import {
  Box,
  Button,
  Container,
  Heading,
  Text,
  VStack,
  Grid,
  Image,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  SimpleGrid,
  Card,
  CardBody,
  Icon,
  Flex,
  useColorModeValue,
  Badge,
  Divider,
  Circle
} from "@chakra-ui/react";
import { useClerk } from "@clerk/clerk-react";
import { FaGift, FaCalendarAlt, FaClock, FaTrophy } from "react-icons/fa";
import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import CountdownDisplay from "./CountdownDisplay";
import tree from '/tree.svg';
import gift from '/gift.svg';
import mitten from '/mitten.svg';
import firework from '/firework.svg';
import stocking from '/stocking.svg';
import hat from '/hat.svg';
import Snowfall from './Snowfall';
import advent from '/advent_tp.png';
import ShakingImage from "./ShakingImage";
import { useNavigate } from 'react-router-dom';

function Welcome() {
  // Remove unused state and effects, keep only what's needed
  const navigate = useNavigate();

  return (
    <Box position="relative" overflow="hidden">
      <Snowfall />
      <Container maxW="container.xl" px={{ base: 4, md: 6 }} py={{ base: 6, md: 10 }}>
        <VStack spacing={{ base: 6, md: 8 }} align="center" mb={{ base: 12, md: 16 }}>
          <Box></Box>
          <Box></Box>
          
            <Heading
              as="h1"
              size={{ base: "xl", md: "2xl" }}
              textAlign="center"
              px={2}
          >
            The SQL Advent Calendar 2024 is officially completed!
          </Heading>
          <Image 
              src={advent} 
              alt="Advent Calendar Preview" 
              maxW="400px"
              w="100%"
              borderRadius="md"
              mx="auto"
              objectFit="cover"
            />
          
          <Text
            fontSize={{ base: "lg", md: "xl" }}
            textAlign="center"
            color="primary.400"
            px={2}
          >
            Hi there! This SQL challenge has officially wrapped up.
            <br />
            <br />
            If you participated, thank you from the bottom of my heart! ❤️
            <br />
            <br />
            If you're interested in participating in future SQL challenges, sign up for my Ask Data Dawn newsletter below:
          </Text>

          <Box border="2px solid" borderColor="primary.500" borderRadius="md" overflow="hidden">
            <iframe 
              src="https://embeds.beehiiv.com/927d30df-5493-41ea-b43e-ad35b8117b67?slim=true" 
              data-test-id="beehiiv-embed" 
              height="52" 
              frameBorder="0" 
              scrolling="no" 
              style={{ margin: 0, backgroundColor: 'transparent' }}
            />
          </Box>

          <Text
            fontSize={{ base: "lg", md: "xl" }}
            textAlign="center"
            color="primary.400"
            px={2}
          >
            Thank you,
            <br />
            Dawn 🫶
          </Text>
        </VStack>
      </Container>
    </Box>
  );
}

export default Welcome;


// function Welcome() {
//   const { openSignIn, openSignUp } = useClerk();
//   const [timeLeft, setTimeLeft] = useState({ days: 0, hours: 0, minutes: 0, seconds: 0 });
//   const [signUpCount, setSignUpCount] = useState(0);
//   const navigate = useNavigate();

  
//   // Add new useEffect for fetching user count
//   useEffect(() => {
//     fetch('/api/getUserSignUpCount')
//       .then(response => response.json())
//       .then(data => setSignUpCount(data.count))
//       .catch(error => console.error('Error fetching sign up count:', error));
//   }, []);

//     const handleSignUp = () => {
//       navigate('/sign-up');
//     };
  
//       const handleSignIn = () => {
//         navigate('/sign-in');
//       };
    
  
//   return (
//     <Box position="relative" overflow="hidden">
//       <Snowfall />
//       <Container maxW="container.xl" px={{ base: 4, md: 6 }} py={{ base: 6, md: 10 }}>
//         <VStack spacing={{ base: 6, md: 8 }} align="center" mb={{ base: 12, md: 16 }}>
//             <CountdownDisplay />
//           <Heading
//             as="h1"
//             size={{ base: "xl", md: "2xl" }}
//             textAlign="center"
//             px={2}
//           >
//             SQL Advent Calendar
//             <Box as="span" display="inline-block" verticalAlign="middle">
//               <ShakingImage 
//                 src={stocking}
//                 alt="Stocking"
//               />
//             </Box>
//           </Heading>
                    
//           <Text
//             fontSize={{ base: "lg", md: "xl" }}
//             textAlign="center"
//             fontWeight="bold"
//             color="primary.400"
//             px={2}
//           >
//             Level up your SQL Skills this Holiday Season
//             with the FREE SQL Advent Calendar!
//           </Text>

//           <Box px={2} width="full">
//             <Image 
//               src={advent} 
//               alt="Advent Calendar Preview" 
//               maxW="400px"
//               w="100%"
//               borderRadius="md"
//               mx="auto"
//               objectFit="cover"
//             />
//           </Box>
          
//           <VStack spacing={2} px={2}>
//             <Text fontSize={{ base: "sm", md: "md" }} textAlign="center">
//               24 days, 24 questions, unlimited fun. Practice SQL daily and celebrate your progress.
//             </Text>
            
//             <Text fontSize={{ base: "sm", md: "md" }} textAlign="center" color="primary.400">
//               Complete all 24 questions by December 31, 2024, and enter for a chance to win a $100 Gift Card!
//             </Text>
//           </VStack>

//           <Box
//             bg="secondary.100"
//             p={{ base: 3, md: 4 }}
//             borderRadius="lg"
//             width="full"
//             maxW="600px"
//             mx={2}
//           >
//             <Flex 
//               alignItems="center" 
//               gap={{ base: 2, md: 3 }}
//               justifyContent="center"
//               flexDirection={{ base: "column", md: "row" }}
//             >
//               <Flex>
//                 {['/person3.jpg', '/person2.jpg', '/person1.jpg'].map((src, index) => (
//                   <Box
//                     key={index}
//                     width={{ base: "28px", md: "32px" }}
//                     height={{ base: "28px", md: "32px" }}
//                     borderRadius="full"
//                     border="2px solid white"
//                     overflow="hidden"
//                     marginLeft={index > 0 ? '-15px' : '0'}
//                     zIndex={3 - index}
//                   >
//                     <Image
//                       src={src}
//                       alt={`Person ${3 - index}`}
//                       width="100%"
//                       height="100%"
//                       objectFit="cover"
//                     />
//                   </Box>
//                 ))}
//               </Flex>

//               <Text
//                 fontSize={{ base: "sm", md: "md" }}
//                 color="black"
//                 textAlign="center"
//               >
//                 Join the {signUpCount.toLocaleString()} SQL enthusiasts who are signed up!
//               </Text>
//             </Flex>
//           </Box>

//           <Flex 
//             gap={{ base: 2, md: 4 }}
//             flexDirection={{ base: "column", md: "row" }}
//             width={{ base: "full", md: "auto" }}
//             px={2}
//           >
//             <Button
//               size={{ base: "md", md: "lg" }}
//               width={{ base: "full", md: "auto" }}
//               onClick={handleSignUp}
//             >
//               Sign Up for Free!
//             </Button>
//             <Button
//               size={{ base: "md", md: "lg" }}
//               variant="outline"
//               width={{ base: "full", md: "auto" }}
//               onClick={handleSignIn}
//             >
//               Already Registered? Sign In
//             </Button>       
//           </Flex>

          
//         </VStack>

//         {/* How It Works */}
//         <Box mb={16}>
//           <Heading textAlign="center" mb={8}>
//             <Box as="span" display="inline-block" verticalAlign="middle">
//               <ShakingImage 
//                 src={gift}
//                 alt="Gift"
//               />
//             </Box>
//             How It Works
//           </Heading>
//           <Grid templateColumns={{ base: "1fr", md: "repeat(4, 1fr)" }} gap={8} position="relative">
//             {/* Add connecting lines between steps */}
//             <Box 
//               display={{ base: "none", md: "block" }}
//               position="absolute"
//               top="2rem"
//               left="15%"
//               right="15%"
//               height="2px"
//               bg="primary.200"
//               zIndex={0}
//             />
//             {[
//               {
//                 icon: FaGift,
//                 title: "Sign Up",
//                 description: "Create your free account"
//               },
//               {
//                 icon: FaCalendarAlt,
//                 title: "Solve Daily",
//                 description: "Complete SQL challenges of varying difficulty"
//               },
//               {
//                 icon: FaClock,
//                 title: "Track Progress",
//                 description: "Light up your calendar"
//               },
//               {
//                 icon: FaTrophy,
//                 title: "Win Prize",
//                 description: "Enter the $100 gift card drawing"
//               }
//             ].map((step, index) => (
//               <VStack key={index} spacing={4} position="relative" zIndex={1}>
//                 <Circle size="50px" bg="white" border="2px solid" borderColor="primary.500">
//                   <Icon as={step.icon} w={6} h={6} color="primary.500" />
//                 </Circle>
//                 <Heading size="md">{step.title}</Heading>
//                 <Text textAlign="center">{step.description}</Text>
//               </VStack>
//             ))}
//           </Grid>
//         </Box>


//         {/* FAQ Section */}
//         <Box mb={16}>
//           <Heading textAlign="center" mb={8}>
//             Frequently Asked Questions
//             <Box as="span" display="inline-block" verticalAlign="middle">
//               <ShakingImage 
//                 src={firework}
//                 alt="Firework"
//               />
//             </Box>
//           </Heading>
//           <Accordion allowMultiple maxW="container.md" mx="auto">
//             {[
//               {
//                 title: "What is a SQL advent calendar?",
//                 content: "It's a 24-day countdown to Christmas, but instead of getting a small gift every day, you get a SQL question each day!"
//               },
//               {
//                 title: "How much SQL experience is needed?",
//                 content: "You just need to know the basics of SQL to sign up! The questions range from beginner to advanced. Plus, you have an unlimited number of attempts to complete each question. I encourage to sign up no matter what level you are currently at!"
//               },
//               {
//                 title: "Do you have an example of the SQL questions?",
//                 content: (
//                   <>
//                     Of course!
//                     <br /><br />
//                     <strong>Here is an example of an easy question:</strong> Given a table called elves with columns name and age, write a query to find the average age of all the elves.
//                     <br /><br />
//                     <strong>An example of a more difficult question:</strong> Given a table called polar_bear_cubs with columns name, weight and day. Calculate the average day-over-day weight gain for the cubs.
//                     <br /><br />
//                     In the actual challenge, you will also receive the respective sample tables for the questions.
//                   </>
//                 )
//               },
//               {
//                 title: "How do I qualify to enter the drawing for the $100 gift card?",
//                 content: "To qualify, simply finish all 24 SQL questions by December 31 at 11:59pm ET. Everyone who completes the challenge will be entered into the random drawing, and one lucky winner will be selected on January 1st!"
//               },
//               {
//                 title: "When do new questions become available?",
//                 content: "A new question becomes available each day at 12PM ET. You can complete the question as many times as you want."
//               },
//               {
//                 title: "Why is this free?",
//                 content: "The holiday season is all about giving, and I wanted to create something fun and meaningful for anyone who loves—or is learning—SQL! Plus, this is my first product, and I'm excited to share it with the world."
//               },
//             ].map((faq, index) => (
//               <AccordionItem key={index}>
//                 <h2>
//                   <AccordionButton>
//                     <Box flex="1" textAlign="left" fontWeight="semibold" color="gray.600">
//                       {faq.title}
//                     </Box>
//                     <AccordionIcon />
//                   </AccordionButton>
//                 </h2>
//                 <AccordionPanel pb={4} color="gray.500">
//                   {faq.content}
//                 </AccordionPanel>
//               </AccordionItem>
//             ))}
//           </Accordion>
//         </Box>

//         {/* About Section */}
//         <Box mb={16} textAlign="center">
//           <Heading mb={4}>
//           <Box as="span" display="inline-block" verticalAlign="middle">
//               <ShakingImage 
//                 src={mitten}
//                 alt="Mitten"
//               />
//             </Box>
//             About Me</Heading>
//           <Image
//             borderRadius="full"
//             boxSize="150px"
//             src="/dawn_picture.jpg"
//             alt="Creator"
//             mx="auto"
//             mb={4}
//           />
//           <Text maxW="container.md" mx="auto">
//             Hi, I'm Dawn! I have worked in the Data industry for over 10 years, including companies like Meta and Amazon. I have also coached multiple people to landing their dream Data jobs.
//             I love Christmas and I love SQL, so I created the SQL Advent Calendar to make learning SQL fun and festive!
//           </Text>
//         </Box>

//         {/* Footer CTA */}
//         <VStack spacing={6} mb={8}>
//           <Divider />
//           <Heading size="lg" textAlign="center">
//             Ready to start your SQL journey?
//             <Box as="span" display="inline-block" verticalAlign="middle">
//               <ShakingImage 
//                 src={hat}
//                 alt="Hat"
//               />
//             </Box>
//           </Heading>
//           <Button
//             size="lg"
//             onClick={handleSignUp}
//           >
//             Sign up for the free challenge now!
//           </Button>
          
//           <Box maxW="50%" mx="auto">
//           <Text fontSize="md" color="primary.300" textAlign="center" whiteSpace="normal">
//             <strong>Not ready for a SQL challenge yet?</strong> Sign up for my Ask Data Dawn newsletter to get notified of future challenges
//           </Text>
//           </Box>
//           <Box border="2px solid" borderColor="primary.500" borderRadius="md" overflow="hidden">
//             <iframe src="https://embeds.beehiiv.com/927d30df-5493-41ea-b43e-ad35b8117b67?slim=true" data-test-id="beehiiv-embed" height="52" frameBorder="0" scrolling="no" style={{ margin: 0, backgroundColor: 'transparent' }}></iframe>
//           </Box>
//         </VStack>
//       </Container>
//     </Box>
//   );
// }

// export default Welcome;