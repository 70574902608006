import { useRef, useState, useEffect } from "react";
import { Box, HStack, VStack, Text, Button, Table, Thead, Tbody, Tr, Th, Td, Heading, Tooltip } from "@chakra-ui/react";
import { Editor } from "@monaco-editor/react";
import { CODE_SNIPPETS } from "../constants";
import Output from "./Output";
import { useParams, useNavigate } from 'react-router-dom';
import { useUser } from "@clerk/clerk-react";
import grinchGray from '/grinchGray.svg';
import grinch from '/grinch.svg';
import { ArrowBackIcon } from "@chakra-ui/icons";
import { CHALLENGE_START_DATE, CHALLENGE_START_DATE_TEST_USER } from '../constants';
import { LockIcon } from '@chakra-ui/icons';

const allowedUserIds = [
  'user_2p8a6XDl3SdagFzWIPMIqgIljOI',
  'user_2pRIqxfWUTvRmWPr52oj82kFZd0',
  'user_2pTuXvhQ8qJ5eWyvKoVOXDJP8Xq',
  'user_2pqlDyYH4IKVp2G3kaoqKvu6x32'
];

const AdminCodeEditor = () => {
  const getStorageKey = (userId) => `sql_advent_admin_editor_${userId}`;

  const editorRef = useRef();
  const navigate = useNavigate();
  const [value, setValue] = useState("");
  const [language, setLanguage] = useState("sql");
  const [question, setQuestion] = useState(null);
  const { day } = useParams();
  const { user } = useUser();
  const [formInput, setFormInput] = useState(() => {
    if (user?.id) {
      const savedFormData = localStorage.getItem(`${getStorageKey(user.id)}_form`);
      if (savedFormData) {
        try {
          return JSON.parse(savedFormData);
        } catch (e) {
          console.error('Error parsing saved form data:', e);
        }
      }
    }
    // Default state if no saved data or error in parsing
    return {
      questionText: '',
      tableData: ''
    };
  });

    // Load saved code from localStorage when component mounts
    useEffect(() => {
  if (user?.id) {
    // Load saved code
    const savedCode = localStorage.getItem(getStorageKey(user.id));
    if (savedCode) {
      setValue(savedCode);
    } else {
      setValue(CODE_SNIPPETS[language]);
    }
    
    // Load saved form data
    const savedFormData = localStorage.getItem(`${getStorageKey(user.id)}_form`);
    if (savedFormData) {
      try {
        const parsedFormData = JSON.parse(savedFormData);
        // Only update if the current state is empty
        if (!formInput.questionText && !formInput.tableData) {
          setFormInput(parsedFormData);
        }
      } catch (e) {
        console.error('Error parsing saved form data:', e);
        // Keep existing state on error
      }
    }
    
    // Load saved question
    const savedQuestion = localStorage.getItem(`${getStorageKey(user.id)}_question`);
    if (savedQuestion) {
      try {
        const parsedQuestion = JSON.parse(savedQuestion);
        setQuestion(parsedQuestion);
      } catch (e) {
        console.error('Error parsing saved question:', e);
        // Keep existing state on error
      }
    }
  }
}, [user?.id, language]); // Include language in dependencies

    // Save code to localStorage whenever it changes
    useEffect(() => {
      if (user?.id && value) {  // Remove day check
        localStorage.setItem(getStorageKey(user.id), value);
      }
    }, [value, user?.id]);
    
    // Save form data whenever it changes
    useEffect(() => {
      if (user?.id && formInput.questionText !== '' && formInput.tableData !== '') {
        localStorage.setItem(`${getStorageKey(user.id)}_form`, JSON.stringify(formInput));
      }
    }, [formInput, user?.id]);

    // Save question whenever it changes
    useEffect(() => {
      if (user?.id && question) {
        localStorage.setItem(`${getStorageKey(user.id)}_question`, JSON.stringify(question));
      }
    }, [question, user?.id]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormInput(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handlePopulate = () => {
    let parsedTableData;
    try {
      parsedTableData = JSON.parse(formInput.tableData);
    } catch (error) {
      console.error('Error parsing table data:', error);
      parsedTableData = formInput.tableData; // Keep original if parsing fails
    }

    setQuestion({
      question_day: day,
      question_text: formInput.questionText,
      related_table_data: parsedTableData,
      isLocked: false,
      difficulty: 1
    });
  };

  const onMount = (editor) => {
    editorRef.current = editor;
    editor.focus();
  };

  const renderTable = (data) => {
    if (typeof data === 'string') {
      try {
        data = JSON.parse(data);
      } catch (error) {
        console.error('Error parsing data:', error);
        return null;
      }
    }

    return Object.entries(data).map(([tableName, rows]) => {
      if (!Array.isArray(rows) || rows.length === 0) {
        return null;
      }

      // Get column names from the first row
      const columns = Object.keys(rows[0]);

      return (
        <Box key={tableName} mb={8} maxWidth="75%" mx="auto">
          <Text fontSize="md" mb={4} color="gray.600">
            <strong>Table name: </strong>{tableName}
          </Text>
          <Table variant="simple" size="sm">
            <Thead>
              <Tr>
                {columns.map((column) => (
                  <Th key={column} textTransform="lowercase" fontSize="sm">
                    {column}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {rows.map((row, idx) => (
                <Tr key={idx}>
                  {columns.map((column) => (
                    <Td key={`${idx}-${column}`} fontSize="sm">
                      {row[column]}
                    </Td>
                  ))}
                </Tr>
              ))}
            </Tbody>
          </Table>
        </Box>
      );
    });
  };

  // Load saved form data when component mounts
  useEffect(() => {
    if (user?.id) {
      const savedFormData = localStorage.getItem(`${getStorageKey(user.id)}_form`);
      if (savedFormData) {
        try {
          const parsedFormData = JSON.parse(savedFormData);
          // Only update if the current state is empty
          if (!formInput.questionText && !formInput.tableData) {
            setFormInput(parsedFormData);
          }
        } catch (e) {
          console.error('Error parsing saved form data:', e);
          // Keep existing state on error
        }
      }
    }
  }, [user?.id]); // Ensure this effect runs when user.id changes

  // Check if the user is allowed
  if (!allowedUserIds.includes(user?.id)) {
    return (
      <Box bg="red.500" color="white" p={4} textAlign="center">
        Access Denied
      </Box>
    );
  }

  return (
    <Box bg="background.secondary" minH="100vh" p={4}>
      <HStack spacing={4} align="stretch" h="calc(100vh - 100px)">
        <Box w="50%" h="100%" bg="background.primary" p={4} borderRadius="md" shadow="sm">
          <Heading size="md" mb={4} color="accent.500">Day {day} of SQL Advent Calendar</Heading>
          <Box overflowY="auto" h="calc(100% - 40px)">
            <VStack spacing={4} align="stretch" mb={4}>
              <Box>
                <Text fontWeight="bold" mb={2}>Question Text:</Text>
                <textarea
                  name="questionText"
                  value={formInput.questionText || ''}
                  onChange={handleInputChange}
                  style={{
                    width: '100%',
                    minHeight: '100px',
                    padding: '8px',
                    borderRadius: '4px',
                    border: '1px solid #E2E8F0'
                  }}
                />
              </Box>
              <Box>
                <Text fontWeight="bold" mb={2}>Table Data (JSON format):</Text>
                <textarea
                  name="tableData"
                  value={formInput.tableData || ''}
                  onChange={handleInputChange}
                  style={{
                    width: '100%',
                    minHeight: '100px',
                    padding: '8px',
                    borderRadius: '4px',
                    border: '1px solid #E2E8F0'
                  }}
                />
              </Box>
              <Button colorScheme="brand" onClick={handlePopulate}>
                Populate
              </Button>
            </VStack>

            {question && !question.isLocked && (
              <Box mt={6}>
                <Text fontWeight="bold" color="gray.600">Populated Question:</Text>
                <Text 
                  fontSize="lg" 
                  color="gray.600" 
                  dangerouslySetInnerHTML={{ __html: question.question_text }}
                />
                <Box mb={4} />
                {renderTable(question.related_table_data)}
              </Box>
            )}
          </Box>
          <Button mb={4} onClick={() => navigate('/app/advent-calendar')} colorScheme="brand" leftIcon={<ArrowBackIcon />} size="sm">
            Back to Advent Calendar
          </Button>
        </Box>

        <VStack w="50%" spacing={4} h="100%">
          <Box 
            w="100%" 
            h="50%" 
            bg="background.primary" 
            p={4} 
            borderRadius="md" 
            shadow="sm"
            position="relative"
            resize="vertical"
            overflow="auto"
            sx={{
              '&:hover': {
                cursor: 'row-resize'
              }
            }}
          >
            <HStack spacing={2} align="center">
              <Heading size="md" mb={4} color="accent.500">Write your SQL query here</Heading>
              <Tooltip label="This app uses SQLite to process your SQL queries. SQLite follows most standard SQL conventions, but may have slight differences then PostgreSQL or mySQL.">
                <Box 
                  as="span"
                  color="accent.500"
                  fontSize="md"
                  cursor="help"
                  verticalAlign="text-top"
                  mt="-16px"
                  position="relative"
                  left="-4px"
                  display="inline-block"
                >
                  ⓘ
                </Box>
              </Tooltip>
            </HStack>
            <Editor
              options={{
                minimap: { enabled: false },
                fontSize: 16
              }}
              height="calc(100% - 40px)"
              theme="vs-dark"
              language={language}
              defaultValue={CODE_SNIPPETS[language]}
              onMount={onMount}
              value={value}
              onChange={(value) => setValue(value)}
            />
          </Box>
          
          <Box 
            w="100%" 
            flex={1}
            bg="background.primary" 
            p={4} 
            borderRadius="md" 
            shadow="sm"
            position="relative"
            overflow="auto"
          >
            <Heading size="md" mb={4} color="accent.500">Is your query correct?</Heading>
            <Output editorRef={editorRef} language={language} question={question} isLocked={question?.isLocked} />
          </Box>
        </VStack>
      </HStack>
    </Box>
  );
};

export default AdminCodeEditor;