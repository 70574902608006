import { useState, useEffect } from 'react';
import { useUser } from '@clerk/clerk-react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Container,
  Heading,
  NumberInput,
  NumberInputField,
  Textarea,
  Alert,
  AlertIcon,
  HStack,
  Text,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Card,
  CardBody,
  Progress,
} from '@chakra-ui/react';

const AdminLLMSimulator = () => {
  const { user } = useUser();
  
  const allowedUserIds = [
    'user_2p8a6XDl3SdagFzWIPMIqgIljOI',
    'user_2pRIqxfWUTvRmWPr52oj82kFZd0',
    'user_2pTuXvhQ8qJ5eWyvKoVOXDJP8Xq',
    'user_2pqlDyYH4IKVp2G3kaoqKvu6x32'
  ];

  const hasAccess = allowedUserIds.includes(user?.id);

  const [formData, setFormData] = useState({
    executionStatus: 'success',
    executionError: '',
    question_text: 'We are looking for cheap gifts at the market. Which vendors are selling items priced below $10? List the unique vendor names.',
    related_table_data: `[
  {
    "tableName": "vendors",
    "rows": [
      {
        "vendor_id": 1,
        "vendor_name": "Cozy Crafts",
        "market_location": "Downtown Square"
      },
      {
        "vendor_id": 2,
        "vendor_name": "Sweet Treats",
        "market_location": "Central Park"
      },
      {
        "vendor_id": 3,
        "vendor_name": "Winter Warmers",
        "market_location": "Downtown Square"
      }
    ]
  },
  {
    "tableName": "item_prices",
    "rows": [
      {
        "item_id": 1,
        "vendor_id": 1,
        "item_name": "Knitted Scarf",
        "price_usd": 25
      },
      {
        "item_id": 2,
        "vendor_id": 2,
        "item_name": "Hot Chocolate",
        "price_usd": 5
      },
      {
        "item_id": 3,
        "vendor_id": 2,
        "item_name": "Gingerbread Cookie",
        "price_usd": 3.5
      },
      {
        "item_id": 4,
        "vendor_id": 3,
        "item_name": "Wool Hat",
        "price_usd": 18
      },
      {
        "item_id": 5,
        "vendor_id": 3,
        "item_name": "Santa Pin",
        "price_usd": 2
      }
    ]
  }
]`,
    userQuery: `select 
vendor_name
from vendors 
join item_prices
 on vendors.vendor_id = item_prices.vendor_id
where price_usd < 10
`,
    numberOfSimulations: 1
  });

  // New state for simulation results
  const [simulationResults, setSimulationResults] = useState({
    accepted: 0,
    rejected: 0,
    rejectionReasons: [],
    currentLoop: 0,
    isRunning: false,
    error: null
  });

  // Load cached simulation results on component mount
  useEffect(() => {
    const cachedResults = localStorage.getItem('simulationResults');
    if (cachedResults) {
      setSimulationResults(JSON.parse(cachedResults));
    }
  }, []);

  // Load cached form data on component mount
  useEffect(() => {
    const cachedForm = localStorage.getItem('simulatorFormData');
    if (cachedForm) {
      setFormData(JSON.parse(cachedForm));
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => {
      const newData = {
        ...prev,
        [name]: value
      };
      localStorage.setItem('simulatorFormData', JSON.stringify(newData));
      return newData;
    });
  };

  const handleNumberChange = (value) => {
    setFormData(prev => {
      const newData = {
        ...prev,
        numberOfSimulations: value
      };
      localStorage.setItem('simulatorFormData', JSON.stringify(newData));
      return newData;
    });
  };

  const runSimulation = async () => {
    // Reset results before starting new simulation
    setSimulationResults({
      accepted: 0,
      rejected: 0,
      rejectionReasons: [],
      currentLoop: 0,
      isRunning: true,
      error: null
    });

    try {
      for (let i = 0; i < formData.numberOfSimulations; i++) {
        // Update current loop count
        setSimulationResults(prev => ({
          ...prev,
          currentLoop: i + 1
        }));

        // Make API call
        const response = await fetch('/api/evaluateQuery', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            userQuery: formData.userQuery,
            question_text: formData.question_text,
            related_table_data: formData.related_table_data,
            executionStatus: formData.executionStatus,
            executionError: formData.executionError
          })
        });

        if (!response.ok) {
          throw new Error('API request failed');
        }

        const validation = await response.json();

        // Update results based on validation
        setSimulationResults(prev => ({
          ...prev,
          accepted: validation.correct ? prev.accepted + 1 : prev.accepted,
          rejected: !validation.correct ? prev.rejected + 1 : prev.rejected,
          rejectionReasons: !validation.correct 
            ? [...prev.rejectionReasons, { loop: i + 1, reason: validation.feedback }]
            : prev.rejectionReasons
        }));
      }
    } catch (error) {
      setSimulationResults(prev => ({
        ...prev,
        isRunning: false,
        error: error.message
      }));
      return;
    }

    // After marking simulation as complete, cache the results
    setSimulationResults(prev => {
      const updatedResults = { ...prev, isRunning: false };
      localStorage.setItem('simulationResults', JSON.stringify(updatedResults)); // Cache the results
      return updatedResults;
    });
  };

  if (!hasAccess) {
    return (
      <Container maxW="container.md" py={8}>
        <Alert status="error">
          <AlertIcon />
          Access Denied
        </Alert>
      </Container>
    );
  }

  return (
    <Container maxW="container.xl" py={8}>
      <HStack align="flex-start" spacing={8}>
        {/* Left side - Form */}
        <Box width="50%" maxW="600px">
          <VStack spacing={6} align="stretch">
            <Heading>LLM Simulator</Heading>
            
            <FormControl>
              <FormLabel>Execution Status</FormLabel>
              <Input
                name="executionStatus"
                value={formData.executionStatus}
                onChange={handleInputChange}
                borderWidth="1px"
                borderColor="gray.300"
              />
            </FormControl>

            <FormControl>
              <FormLabel>Execution Error</FormLabel>
              <Input
                name="executionError"
                value={formData.executionError}
                onChange={handleInputChange}
                borderWidth="1px"
                borderColor="gray.300"
              />
            </FormControl>

            <FormControl>
              <FormLabel>Question Text</FormLabel>
              <Textarea
                name="question_text"
                value={formData.question_text}
                onChange={handleInputChange}
                borderWidth="1px"
                borderColor="gray.300"
              />
            </FormControl>

            <FormControl>
              <FormLabel>Related Table Data</FormLabel>
              <Textarea
                name="related_table_data"
                value={formData.related_table_data}
                onChange={handleInputChange}
                borderWidth="1px"
                borderColor="gray.300"
              />
            </FormControl>

            <FormControl>
              <FormLabel>User Query</FormLabel>
              <Textarea
                name="userQuery"
                value={formData.userQuery}
                onChange={handleInputChange}
                borderWidth="1px"
                borderColor="gray.300"
              />
            </FormControl>

            <FormControl>
              <FormLabel>Number of Simulations</FormLabel>
              <NumberInput
                min={1}
                value={formData.numberOfSimulations}
                onChange={handleNumberChange}
              >
                <NumberInputField borderWidth="1px" borderColor="gray.300" />
              </NumberInput>
            </FormControl>

            <Button
              colorScheme="blue"
              size="lg"
              onClick={runSimulation}
              width="full"
              isLoading={simulationResults.isRunning}
              loadingText="Running Simulation"
            >
              Run Simulation
            </Button>
          </VStack>
        </Box>

        {/* Right side - Results */}
        <Box width="50%" maxW="600px">
          <VStack spacing={6} align="stretch">
            <Card>
              <CardBody>
                <VStack spacing={4} align="stretch">
                  <Heading size="md">Simulation Progress</Heading>
                  <Text color="gray.100">
                    Loop: {simulationResults.currentLoop} / {formData.numberOfSimulations}
                  </Text>
                  <Progress 
                    value={(simulationResults.currentLoop / formData.numberOfSimulations) * 100} 
                    size="sm" 
                    colorScheme="blue"
                  />
                </VStack>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <VStack spacing={4} align="stretch">
                  <Heading size="md">Scoreboard</Heading>
                  <HStack justify="space-between">
                    <Text color = "gray.100">Accepted: {simulationResults.accepted}</Text>
                    <Text color = "gray.100">Rejected: {simulationResults.rejected}</Text>
                  </HStack>
                </VStack>
              </CardBody>
            </Card>

            {simulationResults.error && (
              <Alert status="error">
                <AlertIcon />
                {simulationResults.error}
              </Alert>
            )}

            {simulationResults.rejectionReasons.length > 0 && (
              <Card>
                <CardBody>
                  <VStack spacing={4} align="stretch">
                    <Heading size="md">Rejection Reasons</Heading>
                    <Table variant="simple" size="sm">
                      <Thead>
                        <Tr>
                          <Th>Loop #</Th>
                          <Th>Reason</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {simulationResults.rejectionReasons.map((rejection, index) => (
                          <Tr key={index}>
                            <Td>{rejection.loop}</Td>
                            <Td>{rejection.reason}</Td>
                          </Tr>
                        ))}
                      </Tbody>
                    </Table>
                  </VStack>
                </CardBody>
              </Card>
            )}
          </VStack>
        </Box>
      </HStack>
    </Container>
  );
};

export default AdminLLMSimulator;